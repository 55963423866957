import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Image from '../components/uielements/Image'
import MainTitle from '../components/uielements/MainTitle'
import Spacer from '../components/uielements/Spacer'
import Seo from '../components/seo'
import Columns from '../components/uielements/Columns'
import ReadMore from '../components/buildingBlocks/ReadMore'
import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import RichContent from '../components/uielements/RichContent/richContent'

const EvaluationPage = ({ data: { evaluations } }) => {
    const { i18n } = useTranslation()

    return (
        <Layout>
            <Wrapper>
                {evaluations.nodes.map(evaluation => {
                    return (
                        evaluation.node_locale === i18n.language && (
                            <>
                                <MainTitle>{evaluation.title}</MainTitle>
                                <Seo title={evaluation.title} />
                                <Spacer top="2" />
                                <Columns>
                                    <Image obj={evaluation.featuredImage} />
                                    <RichContent body={evaluation?.body?.raw} />
                                    <Spacer top="2" bottom="2" />
                                    {i18n.language === 'el-GR' && (
                                        <ReadMore {...evaluation} />
                                    )}
                                </Columns>
                                <Spacer top="5" />
                            </>
                        )
                    )
                })}
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query EvaluationQuery {
        evaluations: allContentfulPage(
            filter: {
                category: { elemMatch: { slug: { eq: "evaluation-cat" } } }
            }
        ) {
            nodes {
                id
                node_locale
                slug
                title
                featuredImage {
                    url
                    title
                    gatsbyImageData(
                        quality: 75
                        jpegProgressive: true
                        sizes: "1920, 960, 480"
                        cropFocus: CENTER
                    )
                }
                body {
                    raw
                }
                readMore {
                    title
                    postType
                    slug
                }
            }
        }
    }
`

export default EvaluationPage
